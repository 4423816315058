import { useThemeStore } from '@/lib/store';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// Import all components directly
import FilterResultsBar from '@/components/filter-results-bar';
import Home from '@/components/home';
import ProductCatalog from '@/components/product-catalog';
import ProductDetail from '@/components/product-detail';
import ProductDetailPopover from '@/components/product-detail-popover';
import ProductList from '@/components/product-list';
import QuoteRequest from '@/components/quote-request';
import QuoteRequestForm from '@/components/quote-request-form';
import ScrollAreaDynamic from '@/components/scroll-area-dynamic';
import ToggleButtonListGrid from '@/components/toggle-button-list-grid';
import WheelCard from '@/components/wheel-card';
import WheelCarousel from '@/components/wheel-carousel';
import WheelConfigurator from '@/components/wheel-configurator/wheel-configurator';
import WheelInteractivesNavigationList from './wheel-interactives-navigation-list';

const DynamicComponentLoader = ({
  componentNameAsProp,
  props,
}: {
  componentNameAsProp?: string;
  props?: any;
}) => {
  const [searchParams] = useSearchParams();
  const componentName = searchParams.get('component');

  // Direct component map without dynamic imports
  const componentMap: { [key: string]: any } = {
    home: Home,
    'filter-results-bar': FilterResultsBar,
    'product-catalog': ProductCatalog,
    'product-detail-popover': ProductDetailPopover,
    'product-detail': ProductDetail,
    'product-list': ProductList,
    'quote-request-form': QuoteRequestForm,
    'quote-request': QuoteRequest,
    'scroll-area-dynamic': ScrollAreaDynamic,
    'toggle-button-list-grid': ToggleButtonListGrid,
    'wheel-card': WheelCard,
    'wheel-carousel': WheelCarousel,
    'wheel-configurator': WheelConfigurator,
    'wheel-interactives-navigation-list': WheelInteractivesNavigationList,
  };

  // Get the component to render based on componentName or componentNameAsProp
  const ComponentToRender =
    componentMap[componentName as string] ||
    componentMap[componentNameAsProp as string];

  const isDark = useThemeStore((state) => state.isDark);

  useEffect(() => {
    if (isDark) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDark]);

  return (
    <div className="">
      {ComponentToRender ? (
        <ComponentToRender {...props} />
      ) : (
        <div>Component not found</div>
      )}
    </div>
  );
};

export default React.memo(DynamicComponentLoader);

// // autoviz-component-monorepo/packages/product-catalog/src/components/dynamic-component-loader.tsx

// import { useStore, useThemeStore } from "@/lib/store";
// import React, { Suspense, lazy, useEffect, useMemo } from "react";
// import { useLocation, useSearchParams } from 'react-router-dom';

// const DynamicComponentLoader = ({
//   componentNameAsProp,
//   props,
// }: {
//   componentNameAsProp?: string;
//   props?: any;
// }) => {
//   const [searchParams] = useSearchParams();
//   const componentName = searchParams.get('component');

//   const componentMap: { [key: string]: any } = {
//     home: () => import(/* webpackChunkName: "home" */ '@/components/home'),
//     'filter-results-bar': () =>
//       import(
//         /* webpackChunkName: "filter-results-bar" */ '@/components/filter-results-bar'
//       ),
//     'product-catalog': () =>
//       import(
//         /* webpackChunkName: "product-catalog" */ '@/components/product-catalog'
//       ),
//     'product-detail-popover': () =>
//       import(
//         /* webpackChunkName: "product-detail-popover" */ '@/components/product-detail-popover'
//       ),
//     'product-detail': () =>
//       import(
//         /* webpackChunkName: "product-detail" */ '@/components/product-detail'
//       ),
//     'product-list': () =>
//       import(
//         /* webpackChunkName: "product-list" */ '@/components/product-list'
//       ),
//     'quote-request-form': () =>
//       import(
//         /* webpackChunkName: "quote-request-form" */ '@/components/quote-request-form'
//       ),
//     'quote-request': () =>
//       import(
//         /* webpackChunkName: "quote-request" */ '@/components/quote-request'
//       ),
//     'scroll-area-dynamic': () =>
//       import(
//         /* webpackChunkName: "scroll-area-dynamic" */ '@/components/scroll-area-dynamic'
//       ),
//     'toggle-button-list-grid': () =>
//       import(
//         /* webpackChunkName: "toggle-button-list-grid" */ '@/components/toggle-button-list-grid'
//       ),
//     'wheel-card': () =>
//       import(/* webpackChunkName: "wheel-card" */ '@/components/wheel-card'),
//     'wheel-carousel': () =>
//       import(
//         /* webpackChunkName: "wheel-carousel" */ '@/components/wheel-carousel'
//       ),
//     'wheel-configurator': () =>
//       import(
//         /* webpackChunkName: "wheel-configurator" */ '@/components/wheel-configurator/wheel-configurator'
//       ),
//     'wheel-interactives-navigation-list': () =>
//       import(
//         /* webpackChunkName: "wheel-interactives-navigation-list" */ '@/components/wheel-interactives-navigation-list'
//       ),
//   };

//   const ComponentToRender = useMemo(() => {
//     if (componentName && componentMap[componentName]) {
//       return lazy(componentMap[componentName]);
//     } else if (componentNameAsProp && componentMap[componentNameAsProp]) {
//       return lazy(componentMap[componentNameAsProp]);
//     }
//     return null;
//   }, [componentName, componentNameAsProp]);

//   const isDark = useThemeStore((state) => state.isDark);

//   useEffect(() => {
//     if (isDark) {
//       document.body.classList.add('dark');
//     } else {
//       document.body.classList.remove('dark');
//     }
//   }, [isDark]);

//   const location = useLocation();
//   const setNavigationContextRootPath = useStore(
//     (state) => state.setNavigationContextRootPath,
//   );

//   // useEffect(() => {
//   //   console.log(`location.pathname`, location.pathname);
//   //   setNavigationContextRootPath(
//   //     location.pathname.indexOf(productCatalogPath) > -1
//   //       ? productCatalogPath
//   //       : configuratorPath
//   //   );
//   // }, [location.pathname]);

//   return (
//     <div className="">
//       {ComponentToRender ? (
//         <Suspense fallback={<></>}>
//           <ComponentToRender {...props} />
//         </Suspense>
//       ) : (
//         <div>Component not found</div>
//       )}
//     </div>
//   );
// };

// export default React.memo(DynamicComponentLoader);
