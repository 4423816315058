export default function SvgIconVehicle() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-[#191919] dark:stroke-[#fff]"
    >
      <path
        d="M7.50594 14.1667C7.50594 15.0872 6.75975 15.8333 5.83927 15.8333C4.9188 15.8333 4.17261 15.0872 4.17261 14.1667C4.17261 13.2462 4.9188 12.5 5.83927 12.5C6.75975 12.5 7.50594 13.2462 7.50594 14.1667Z"
        stroke="#191919"
        strokeWidth="1.2"
        className="stroke-[#191919] dark:stroke-[#fff]"
      />
      <path
        d="M15.8394 14.1667C15.8394 15.0872 15.0933 15.8333 14.1728 15.8333C13.2523 15.8333 12.5061 15.0872 12.5061 14.1667C12.5061 13.2462 13.2523 12.5 14.1728 12.5C15.0933 12.5 15.8394 13.2462 15.8394 14.1667Z"
        stroke="#191919"
        strokeWidth="1.2"
        className="stroke-[#191919] dark:stroke-[#fff]"
      />
      <path
        d="M1.67277 8.33341H15.0061M1.67277 8.33341C1.67277 8.98341 1.65611 10.8667 1.6761 12.7167C1.70607 13.3167 1.806 13.8167 4.17419 14.1667M1.67277 8.33341C1.85263 6.88341 2.63537 5.16675 3.03506 4.51675M7.5061 8.33341V4.16675M12.4978 14.1667H7.50166M1.68609 4.16675H10.1996C10.1996 4.16675 10.6493 4.16675 11.0489 4.20675C11.7984 4.27675 12.4279 4.61675 13.0574 5.46675C13.724 6.36675 14.2365 7.50675 14.916 8.11675C16.0452 9.13041 18.1935 8.81675 18.3134 10.9667C18.3434 12.0667 18.3434 13.2667 18.2934 13.4667C18.2132 14.0557 17.7591 14.1518 17.1942 14.1667C16.7041 14.1797 16.1132 14.1435 15.8253 14.1667"
        stroke="#191919"
        strokeWidth="1.2"
        strokeLinecap="round"
        className="stroke-[#191919] dark:stroke-[#fff]"
      />
    </svg>
  );
}
