export default function SvgIconFilter() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 17"
      fill="none"
      className="stroke-[#191919] dark:stroke-[#fff]"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2.5H3.33334C2.39053 2.5 1.91913 2.5 1.62624 2.7748C1.33334 3.0496 1.33334 3.49188 1.33334 4.37644V4.83632C1.33334 5.52821 1.33334 5.87416 1.50641 6.16095C1.67948 6.44773 1.99566 6.62572 2.62802 6.9817L4.57004 8.07492C4.99432 8.31376 5.20646 8.43318 5.35835 8.56505C5.67467 8.83966 5.8694 9.16234 5.95764 9.55811C6.00001 9.74816 6.00001 9.97054 6.00001 10.4153L6.00001 12.1949C6.00001 12.8013 6.00001 13.1045 6.16796 13.3409C6.33591 13.5772 6.6342 13.6938 7.23078 13.927C8.4832 14.4166 9.10941 14.6614 9.55471 14.3829C10 14.1044 10 13.4679 10 12.1949V10.4153C10 9.97054 10 9.74816 10.0424 9.55811C10.1306 9.16234 10.3254 8.83966 10.6417 8.56505C10.7936 8.43318 11.0057 8.31376 11.43 8.07492L13.372 6.9817C14.0044 6.62572 14.3205 6.44773 14.4936 6.16095C14.6667 5.87416 14.6667 5.52821 14.6667 4.83632V4.37644C14.6667 3.49188 14.6667 3.0496 14.3738 2.7748C14.0809 2.5 13.6095 2.5 12.6667 2.5Z"
        // stroke="#191919"3
        className="stroke-[#191919] dark:stroke-[#fff]"
        strokeWidth="1"
      />
    </svg>
  );
}
