// src/components/wheel-carousel.tsx

import { useModelViewer } from '@/hooks/useModelViewer';
import useSearchParamFunctions from '@/hooks/useSearchParamFunctions';
import { useStore } from '@/lib/store';
import { disableRightClick } from '@/lib/utils';
import { useMediaQuery } from '@react-hook/media-query';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ModelViewer from './model-viewer';
import WheelInteractivesNavigationList from './wheel-interactives-navigation-list';

const WheelCarousel: React.FC<{
  productDetail: any;
  imgUrlBase: string;
  loading: boolean;
}> = ({ productDetail, imgUrlBase, loading }) => {
  ///
  const { updateSearchParam } = useSearchParamFunctions();
  const { modelSrc, setModelSrc, bumpLoaderState } = useModelViewer();

  const newImage = (url: string) => {
    let img: HTMLImageElement = new Image();
    img.src = `${imgUrlBase}${url}`;
    img.setAttribute('data-loaded', 'false');
    img.onload = () => {
      img.setAttribute('data-loaded', 'true');
    };
    return img;
  };
  const slides: HTMLImageElement[] = [];
  if (productDetail?.Img0001) slides.push(newImage(productDetail?.Img0001));
  if (productDetail?.Img0002) slides.push(newImage(productDetail?.Img0002));
  if (productDetail?.Img0003) slides.push(newImage(productDetail?.Img0003));

  const currentAutovizDbWheelModel = useStore(
    (state) => state.currentAutovizDbWheelModel,
  );
  const currentAutovizDbWheelInfo = useStore(
    (state) => state.currentAutovizDbWheelInfo,
  );
  const styleId = useStore((state) => state.styleId);

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const initialImgIndex = Number(urlSearchParams.get('imgIndex') || 0);
  // const initialShow3DModel = Number(urlSearchParams.get('show3DModel') || 0);

  const [imgIndex, setImgIndex] = useState(
    initialImgIndex < slides.length ? initialImgIndex : 0,
  );

  const show3DModel = useStore((state) => state.show3DModel);
  const setShow3DModel = useStore((state) => state.setShow3DModel);

  // useEffect(() => {
  //   updateSearchParam('show3DModel', show3DModel ? 1 : 0);
  // }, [show3DModel]);

  useEffect(() => {
    let imgIndex = initialImgIndex < slides.length ? initialImgIndex : 0;
    setImgIndex(imgIndex);
    updateSearchParam('imgIndex', imgIndex);
    setShow3DModel(false);
  }, [productDetail]);

  useEffect(() => {
    updateSearchParam('imgIndex', imgIndex);
  }, [imgIndex]);

  const wheelSizeMax = '90%';
  const tnSizeMax = '108px';

  const mobileScale = '0.7 0.7 0.7';
  const desktopScale = '0.7 0.7 0.7';
  const mobileCameraTarget = 'auto auto auto';
  const desktopCameraTarget = 'auto auto auto';
  const mobileWidth = '100%';
  const desktopWidth = '80%';
  const mobileHeight = '100%'; //in order to avoid shadow clipping in mobile model viewer needs to be extended vertically
  const desktopHeight = '100%';

  const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  const [cameraTarget, setCameraTarget] = useState(
    !isMobile ? desktopCameraTarget : mobileCameraTarget,
  );
  const [scale, setScale] = useState(!isMobile ? desktopScale : mobileScale);
  const [width, setWidth] = useState(!isMobile ? desktopWidth : mobileWidth);
  const [height, setHeight] = useState(
    !isMobile ? desktopHeight : mobileHeight,
  );

  const shadowStretchHeight = '140%';
  const maxWheelHeight = '1200px';

  const wheelThumbnailsRef = useRef<HTMLDivElement>(null);
  const innerContainer = useRef<HTMLDivElement>(null);
  const mainMediaContainer = useRef<HTMLDivElement>(null);
  const fullColumnHeightContainer = useRef<HTMLDivElement>(null);
  const [tnHasLoaded, setTnHasLoaded] = useState(false);
  const [pageResizeBump, setPageResizeBump] = useState(0);
  useEffect(() => {
    if (
      wheelThumbnailsRef.current &&
      fullColumnHeightContainer.current &&
      innerContainer.current
    ) {
      const diff =
        wheelThumbnailsRef.current.offsetTop -
        50 -
        innerContainer.current.offsetHeight;
      if (screen.width >= 768) {
        innerContainer.current.style.marginTop = `${diff / 2}px`;
      } else {
        innerContainer.current.style.marginTop = '0px';
      }
    }
  }, [
    wheelThumbnailsRef.current,
    innerContainer.current,
    fullColumnHeightContainer.current,
    tnHasLoaded,
    pageResizeBump,
  ]);

  const tnLoaded = (index: number) => () => {
    setTnHasLoaded(true);
  };

  const handleResizeDebounced = debounce(() => {
    setPageResizeBump((prev) => prev + 1);
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', handleResizeDebounced);
    return () => {
      window.removeEventListener('resize', handleResizeDebounced);
      handleResizeDebounced.cancel();
    };
  }, []);

  return (
    <>
      <div
        ref={fullColumnHeightContainer}
        id="wheel-carousel-container"
        className="bg-muted relative w-full border-teal-800 border-0 md:h-[calc(100vh)] md:overflow-hidden"
      >
        <div
          ref={innerContainer}
          className="transition-all  duration-300 mx-auto border-black border-0  w-full "
        >
          <>
            <div
              ref={mainMediaContainer}
              className={` relative order-0  translate-y-[-10%] md:translate-y-[0%] h-full  w-[85%] mx-auto border-blue-500 border-0 mt-10`}
            >
              {/* {modelSrc} */}
              <div
                style={{
                  display: show3DModel === true ? 'block' : 'none',
                }}
                className={`relative  border-green-500 border-0 z-[9]`}
              >
                <ModelViewer
                  src={modelSrc || ''}
                  alt={currentAutovizDbWheelModel?.model}
                  shadowIntensity={1}
                  cameraTarget={cameraTarget}
                  width={width}
                  height={shadowStretchHeight}
                  maxHeight={maxWheelHeight}
                />
              </div>

              <div
                className={``}
                style={{
                  paddingTop: shadowStretchHeight,
                  display: show3DModel !== true ? 'block' : 'none',
                }}
              >
                {' '}
                <img
                  key={imgIndex}
                  loading="eager"
                  src={`${slides[imgIndex]?.src}`}
                  alt={productDetail?.Brand}
                  onContextMenu={disableRightClick}
                  className="absolute inset-x-0 top-1/2 -translate-y-1/2 transform   
                    object-contain aspect-square   mx-auto   border-orange-300 border-0"
                />
              </div>
            </div>
            <div
              ref={wheelThumbnailsRef}
              key={styleId}
              id={`wheel-thumbnails`}
              className="absolute bottom-0 md:bottom-[96px] flex justify-center  mt-4 gap-4  w-full px-4 border-red-600 border-0"
            >
              {slides.map((img, index) => (
                <div
                  style={{
                    maxWidth: `${tnSizeMax}`,
                    maxHeight: `${tnSizeMax}`,
                  }}
                  className={` relative ${(index != imgIndex || show3DModel) && 'opacity-30'}`}
                  key={index}
                >
                  <img
                    loading="lazy"
                    src={`${img.src}`}
                    alt={productDetail?.Brand}
                    onContextMenu={disableRightClick}
                    style={{ opacity: img.getAttribute('data-loaded') ? 1 : 0 }}
                    className="w-full h-full object-cover cursor-pointer transition-all duration-1000"
                    onLoad={tnLoaded(index)}
                    onClick={() => {
                      setShow3DModel(false);
                      setImgIndex(index);
                    }}
                  />
                </div>
              ))}

              {slides?.[0]?.src && (
                <div
                  className={` relative ${!show3DModel && 'opacity-30'}`}
                  style={{
                    maxWidth: `${tnSizeMax}`,
                    maxHeight: `${tnSizeMax}`,
                  }}
                >
                  <div
                    className={`absolute top-0 right-0  flex flex-col items-center justify-center rounded-md px-[6px] py-[2px] bg-black/70 dark:bg-white/70  text-background z-[9] text-xs md:text-base md:top-1 md:right-1 md:py-[4px] md:px-[8px]`}
                  >
                    3D
                  </div>
                  <img
                    loading="lazy"
                    src={`${slides[0].src}`}
                    style={{
                      opacity: slides[0].getAttribute('data-loaded') ? 1 : 0,
                    }}
                    alt={productDetail?.Brand}
                    className={`${!show3DModel && 'opacity-40'} w-full h-full object-cover cursor-pointer`}
                    onClick={() => {
                      setShow3DModel(true);
                      setModelSrc(currentAutovizDbWheelModel?.path || '');
                      bumpLoaderState();
                    }}
                  />
                </div>
              )}
            </div>
          </>
        </div>

        <div
          className={` hidden md:block md:absolute md:bottom-[26px]  pb-4 md:pb-0  left-0 right-0 w-full  mt-4 justify-center border-green-500 border-0`}
        >
          <WheelInteractivesNavigationList
            product={productDetail}
            allowListMode={false}
            wheelInfo={currentAutovizDbWheelInfo}
          />
        </div>
      </div>
    </>
  );
};

const areEqual = (prevProps: any, nextProps: any) => {
  return (
    prevProps.productDetail === nextProps.productDetail &&
    prevProps.imgUrlBase === nextProps.imgUrlBase &&
    prevProps.loading === nextProps.loading &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.scale === nextProps.scale &&
    prevProps.cameraTarget === nextProps.cameraTarget
  );
};

export default React.memo(WheelCarousel, areEqual);

interface ImageWithFadeInProps {
  src: string;
  alt: string;
  className?: string;
}

export const ImageWithFadeIn: React.FC<ImageWithFadeInProps> = ({
  src,
  alt,
  className,
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      console.log(`ImageWithFadeIn`, src);
      setLoaded(true);
    };
  }, [src]);

  return (
    <img
      src={src}
      alt={alt}
      style={{ opacity: loaded ? 1 : 0 }}
      className={`${className} transition-all duration-300`}
    />
  );
};
