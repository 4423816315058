import { Sheet, SheetContent, SheetTrigger } from './ui/sheet';
// import { FilterIcon } from 'lucide-react';
import { useFilterMenuStore, useStore } from '@/lib/store';
import { cn } from '@/lib/utils';
import SvgIconFilter from './assets/svg-icon-filter';
import FilterMenu from './filter-menu-v1.1';

const FilterButton = () => {
  const filtersCount = useFilterMenuStore((state) => state.filtersCount);
  const isThreeColumnView = useStore((state) => state.isThreeColumnView);
  const filterMenuOpen = useFilterMenuStore((state) => state.filterMenuOpen);
  const setFilterMenuOpen = useFilterMenuStore(
    (state) => state.setFilterMenuOpen,
  );

  return (
    <Sheet open={filterMenuOpen} onOpenChange={setFilterMenuOpen}>
      <SheetTrigger
        className={
          'border p-2 px-3 rounded-xl text-base  md:text-lg py-2.5 md:py-3 flex gap-1 items-center justify-center'
        }
      >
        {/* <FilterIcon
          strokeWidth={2}
          size={18}
          className="w-[20px] text-muted-foreground "
          
        /> */}
        <div className="">
          <SvgIconFilter />
        </div>
        {/* <img
        
          src="/images/icons/Filter.svg"
          alt="Filter Icon"
          className="w-[20px] dark:fill-white dark:stroke-white"
        /> */}
        <p>Filters</p>
        {filtersCount > 0 ? (
          <div className="bg-primary text-primary-foreground flex items-center justify-center px-0.5 py-0.5 rounded-md text-sm aspect-square w-5 ml-0.5">
            {filtersCount}
          </div>
        ) : null}
        {/* {filtersCount > 0 && (
            <div className={`min-w-[30px]`}>
              <div className=" rounded-md bg-primary text-primary-foreground text-sm w-[20px] h-[20px] font-bold flex items-center justify-center">
                {filtersCount}
              </div>
            </div>
          )} */}
        {/* <div className="flex gap-2 item-center justify-center  border p-2 rounded-xl py-3 ">
          <FilterIcon
            strokeWidth={2}
            size={18}
            className="w-[20px] text-muted-foreground"
          />
          <div className="text-lg pr-2 pl-1">Filters</div>
          <div className={`min-w-[30px]`}>
              <div className=" rounded-md bg-primary text-primary-foreground text-sm w-[20px] h-[20px] font-bold flex items-center justify-center">
                
                1
              </div>
            </div>
          {filtersCount > 0 && (
            <div className={`min-w-[30px]`}>
              <div className=" rounded-md bg-primary text-primary-foreground text-sm w-[20px] h-[20px] font-bold flex items-center justify-center">
                {filtersCount}
              </div>
            </div>
          )}
        </div> */}
      </SheetTrigger>
      <SheetContent
        hideClose={true}
        className={cn('scroll-hidden safe-padding')}
        side={isThreeColumnView ? 'filterBarRight' : 'filterBarLeft'}
      >
        <FilterMenu />
      </SheetContent>
    </Sheet>
  );
};

export default FilterButton;
