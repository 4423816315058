export default function SvgIconPencil() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9667 2.39872L11.7391 1.62632C13.0189 0.346566 15.0938 0.346566 16.3735 1.62632C17.6533 2.90607 17.6533 4.98096 16.3735 6.26072L15.6011 7.03312M10.9667 2.39872C10.9667 2.39872 11.0633 4.04007 12.5115 5.48832C13.9598 6.93657 15.6011 7.03312 15.6011 7.03312M10.9667 2.39872L3.86568 9.49975C3.38471 9.98072 3.14422 10.2212 2.9374 10.4864C2.69344 10.7992 2.48427 11.1376 2.31361 11.4957C2.16893 11.7993 2.06139 12.1219 1.84629 12.7672L0.93483 15.5016M15.6011 7.03312L8.50007 14.1342C8.01911 14.6151 7.77862 14.8556 7.51346 15.0624C7.20067 15.3064 6.86223 15.5156 6.50414 15.6862C6.20057 15.8309 5.87793 15.9384 5.23264 16.1535L2.49826 17.065M2.49826 17.065L1.82986 17.2878C1.51231 17.3936 1.1622 17.311 0.925516 17.0743C0.688827 16.8376 0.60618 16.4875 0.71203 16.17L0.93483 15.5016M2.49826 17.065L0.93483 15.5016"
        className="stroke-[#191919] dark:stroke-[#fff]"
        stroke="#191919"
        strokeWidth="1.3"
      />
    </svg>
  );
}
