// autoviz-component-monorepo/packages/product-catalog/src/hooks/useModelViewer.tsx

import { toast } from '@/components/ui/use-toast';
import
  {
    PartModel,
    SwatchType,
    applyTexture,
    colors,
    defaultFinish,
    finishAllowed,
    finishes,
    getMaterialByName,
    getPartByName,
    getSwatchByName,
    loadModelViewerScripts,
    nameToDisplayName,
    nameToKey,
  } from '@/components/wheel-configurator/wheel-configurator-helpers';
import { useStore } from '@/lib/store';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSearchParamFunctions from './useSearchParamFunctions';
let MODEL_VIEWER_LOADED = false;

export const useModelViewer = () => {
  useEffect(() => {
    // console.log(`REMOUNTING ++++++++++++++++++++++++++++++++++++++++++++`);
  }, []);
  const modelViewerScriptsLoaded = useStore(
    (state) => state.modelViewerScriptsLoaded,
  );
  const setModelViewerScriptsLoaded = useStore(
    (state) => state.setModelViewerScriptsLoaded,
  );
  useEffect(() => {
    if (modelViewerScriptsLoaded || MODEL_VIEWER_LOADED) return;
    MODEL_VIEWER_LOADED = true;
    setModelViewerScriptsLoaded(true);
    loadModelViewerScripts();
  }, []);

  const modelViewerRef = useRef<HTMLDivElement & any>(null);
  const modelViewerRefState = useStore((state) => state.modelViewerRefState);
  const setModelViewerRefState = useStore(
    (state) => state.setModelViewerRefState,
  );
  const setModelViewerRefSelf = useStore(
    (state) => state.setModelViewerRefSelf,
  );
  const currentDesignName = useStore((state) => state.currentDesignName);
  const setCurrentDesignName = useStore((state) => state.setCurrentDesignName);
  const arActivated = useStore((state) => state.arActivated);
  const setArActivated = useStore((state) => state.setArActivated);
  const arNeedsActivation = useStore((state) => state.arNeedsActivation);
  const setArNeedsActivation = useStore((state) => state.setArNeedsActivation);
  const setShow3DModel = useStore((state) => state.setShow3DModel);
  const dataObject = useStore((state) => state.dataObject);
  const setDataObject = useStore((state) => state.setDataObject);
  const [dataUrl, setDataUrl] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const currentPartName = useStore((state) => state.currentPartName);
  const setCurrentPartName = useStore((state) => state.setCurrentPartName);
  const [hasError, setHasError] = useState(false);
  const modelSrc = useStore((state) => state.modelSrc);
  const setModelSrc = useStore((state) => state.setModelSrc);

  const [modelInitializedAndBuilt, setModelInitializedAndBuilt] =
    useState(false);
  const location = useLocation();

  const getModel = (): any => {
    // return modelViewerRefState || null;
    return document.getElementById('model-viewer') || null;
  };
  const getMaterials = () => {
    return getModel()?.model?.materials;
  };

  //applyUrlDataToModel
  const paramIsModelPart = (key: string) => dataObject[key] !== undefined;

  const applyUrlDataToModel = useCallback(
    (url?: string) => {
      const urlSearchParams = new URLSearchParams(url || location.search);
      console.log(`============== applyUrlDataToModel`);
      urlSearchParams.forEach((value, key) => {
        const [partName, propertySuffix] = key.split(/_(?=[^_]+$)/); // Split by the last underscore
        if (!paramIsModelPart(partName)) return; //only apply params that are model parts
        const property = propertySuffix === 'c' ? 'color' : 'finish';
        const swatch = getSwatchByName(
          property === 'color' ? colors : finishes,
          value,
        );
        applySwatchToModelViewer({ property, swatch, partName });
      });

      return dataObject;
    },
    [location.search, dataObject],
  );

  useEffect(() => {
    if (dataObject) setModelInitializedAndBuilt(true);
  }, [dataObject]);

  const fullyInitialized = useStore((state) => state.fullyInitialized);
  const setFullyInitialized = useStore((state) => state.setFullyInitialized);

  useEffect(() => {
    const debouncedFunction = debounce(() => {
      if (location.search && dataObject && !fullyInitialized) {
        applyUrlDataToModel();
        setFullyInitialized(true);
      } else if (!location.search) {
        setFullyInitialized(true);
      }
    }, 100);

    debouncedFunction();

    // Cleanup function to cancel the debounce if the effect is re-run
    return () => {
      debouncedFunction.cancel();
    };
  }, [location.search, modelInitializedAndBuilt]);

  const applySwatchToModelViewer = useCallback(
    ({
      swatch,
      property,
      partName,
    }: {
      swatch: SwatchType | null;
      property: 'color' | 'finish';
      partName?: string | null;
    }): PartModel | null => {
      partName = partName || currentPartName;
      const part = getPartByName({
        scopedPartsModel: dataObject,
        partName,
      });
      console.log(`partName`, partName);
      if (!part) return null;
      part[property] = swatch;
      const newPartsModel = { ...dataObject, [partName]: part };
      console.log(`dataObject`, dataObject, newPartsModel);
      setDataObject(newPartsModel);
      addSwatchToUrl({ property, swatch, partName });
      applyMaterialsToModel({ property, swatch, partName });

      return newPartsModel;
    },
    [dataObject, currentPartName],
  );

  //applyMaterialsToModel
  const applyMaterialsToModel = useCallback(
    ({
      swatch,
      property,
      partName,
    }: {
      swatch?: SwatchType | null;
      property: 'color' | 'finish';
      partName: string;
    }) => {
      const materials = getModel()?.model?.materials;
      const modelKey = dataObject?.[partName]?.modelKey;
      const material = getMaterialByName(materials, modelKey);
      if (!swatch || !modelKey || !material) return;

      if (!material?.pbrMetallicRoughness) {
        toast({
          title: 'Material not found on this wheel',
          variant: 'destructive',
        });
        return;
      }

      if (swatch?.metallicFactor)
        material.pbrMetallicRoughness.setMetallicFactor(
          swatch.metallicFactor * 1,
        );

      if (swatch?.roughnessFactor)
        material.pbrMetallicRoughness.setRoughnessFactor(
          swatch.roughnessFactor * 1,
        );

      if (swatch?.hex !== undefined) {
        material.pbrMetallicRoughness.setBaseColorFactor(swatch.hex);
      }

      if (swatch?.texture) applyTexture(getModel(), material, swatch.texture);
      else if (swatch?.texture === null)
        material.pbrMetallicRoughness['metallicRoughnessTexture'].setTexture(
          null,
          '',
        );

      if (property === 'color' && finishAllowed(swatch?.name)) {
        let part = getPartByName({
          scopedPartsModel: dataObject,
          partName,
        });
        applyMaterialsToModel({
          property: 'finish',
          swatch: getSwatchByName(finishes, part?.finish?.name),
          partName: partName,
        });
      }
    },
    [dataObject],
  );

  //buildDataObjectFromModelMaterials
  const buildDataObjectFromModelMaterials = useCallback(
    (modelViewerRef: any) => {
      const materials = modelViewerRef?.model?.materials;
      const newDataObject: PartModel = {};
      if (materials) {
        for (let i = 0; i < materials.length; i++) {
          const material = materials[i];
          if (material.name === 'ROTOR') continue;

          let key = nameToKey(material.name);
          let displayName = nameToDisplayName(material.name);
          let modelKey = material.name;

          if (material.name.includes('CALIPER')) {
            key = 'brake';
            displayName = 'Brake';
            modelKey = 'CALIPER';
          }

          newDataObject[key] = {
            displayName,
            modelKey,
            sort: i,
            color: null,
            finish: defaultFinish,
            enabled: true,
          };
        }
        // console.log(`buildDataObjectFromModelMaterials`, newDataObject);
        setDataObject(newDataObject);
        return newDataObject;
      }
    },
    [],
  );

  const { updateSearchParam } = useSearchParamFunctions();
  //addSwatchToUrl
  const addSwatchToUrl = useCallback(
    ({
      property,
      swatch,
      partName,
    }: {
      property: 'color' | 'finish';
      swatch: SwatchType | null;
      partName: string;
    }) => {
      let suffix = property === 'color' ? '_c' : '_f';
      updateSearchParam(`${partName}${suffix}`, swatch?.name || '');
    },
    [updateSearchParam],
  );

  const savedDesigns = useStore((state) => state.savedDesigns);
  const setSavedDesigns = useStore((state) => state.setSavedDesigns);
  const saveModelViewerImage = (props: any) => {
    let savedDesignsCopy = [...savedDesigns];
    savedDesignsCopy.push({
      displayName: props.inputValue || new Date().getTime(),
      img: props.img,
      config: location.pathname + location.search,
    });
    storeSavedDesigns(savedDesignsCopy);
    toast({
      title: 'Saved!',
      variant: 'default',
    });
  };

  const laodSavedDesigns = () => {
    let curState = [];
    try {
      let stored = localStorage.getItem('savedWheelConfigurations');
      if (stored) curState = JSON.parse(stored as string);
    } catch (error: any) {
      console.error(``, error);
    }
    setSavedDesigns(curState);
  };

  const storeSavedDesigns = (savedDesignsCopy: any) => {
    setSavedDesigns(savedDesignsCopy);
    localStorage.setItem(
      'savedWheelConfigurations',
      JSON.stringify(savedDesignsCopy),
    );
  };

  const deleteSavedDesignByName = (name: any) => {
    let savedDesignsCopy = [...savedDesigns];
    let index = savedDesignsCopy.findIndex(
      (design: any) => design.displayName === name,
    );
    savedDesignsCopy.splice(index, 1);
    storeSavedDesigns(savedDesignsCopy);
  };

  useEffect(() => {
    laodSavedDesigns();
  }, []);

  const loaderStateBump = useStore((state) => state.loaderStateBump);
  const setLoaderStateBump = useStore((state) => state.setLoaderStateBump);
  const bumpLoaderState = () => {
    setLoaderStateBump(loaderStateBump + 1);
  };

  return {
    bumpLoaderState,
    currentDesignName,
    deleteSavedDesignByName,
    setCurrentDesignName,
    savedDesigns,
    setSavedDesigns,
    saveModelViewerImage,
    modelViewerRefState,
    setModelViewerRefState,
    modelViewerScriptsLoaded,
    isLoaded,
    setIsLoaded,
    modelSrc,
    setModelSrc,
    hasError,
    setHasError,
    getModel,
    getMaterials,
    modelViewerRef,
    dataObject,
    setDataObject,
    dataUrl,
    setDataUrl,
    buildDataObjectFromModelMaterials,
    currentPartName,
    setCurrentPartName,
    applySwatchToModelViewer,
    fullyInitialized,
    setFullyInitialized,
    applyUrlDataToModel,
    setModelViewerRefSelf,
  };
};
