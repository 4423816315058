export default function SvgIconDottedCube() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="stroke-[#191919] dark:stroke-[#fff]"
    >
      <path
        d="M16.0582 15.5584L17.9082 14.6334V12.3167M12.3499 8.15008L10.4999 9.07508L12.3499 8.15008ZM10.4999 9.07508L8.64988 8.15008L10.4999 9.07508ZM10.4999 9.07508V11.3917V9.07508ZM17.9082 5.36675L16.0582 6.29175L17.9082 5.36675ZM17.9082 5.36675L16.0582 4.44175L17.9082 5.36675ZM17.9082 5.36675V7.68342V5.36675ZM12.3499 2.59175L10.4999 1.66675L8.64988 2.59175H12.3499ZM3.09155 5.36675L4.94155 4.44175L3.09155 5.36675ZM3.09155 5.36675L4.94155 6.29175L3.09155 5.36675ZM3.09155 5.36675V7.68342V5.36675ZM10.4999 18.3334L8.64988 17.4084L10.4999 18.3334ZM10.4999 18.3334L12.3499 17.4084L10.4999 18.3334ZM10.4999 18.3334V16.0167V18.3334ZM4.94155 15.5584L3.09155 14.6334V12.3167L4.94155 15.5584Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
