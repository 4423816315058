import React from 'react';
import { useThemeStore } from '../lib/store';
import SvgIconMoon from './assets/svg-icon-moon';
import SvgIconSun from './assets/svg-icon-sun';
import ToggleButton from './toggle-button';

const ToggleButtonTheme: React.FC = () => {
  const isDark = useThemeStore((state) => state.isDark);
  const toggleTheme = useThemeStore((state) => state.toggleTheme);
  const iconSize = 14;

  return (
    <>
      <ToggleButton
        containerClassName={``}
        pivotBoolean={!isDark}
        handleToggleFunction={toggleTheme}
        leftContent={
          <>
            <SvgIconSun />
          </>
        }
        rightContent={
          <>
            <SvgIconMoon />
          </>
        }
      />
    </>
  );
};

export default ToggleButtonTheme;
